<template>
  <v-container class="resolution">
    <!--DASHBOARD TOP ROW-->
    <v-row class="topObj mt-0">
      <!--MAIN BUTTONS-->
      <v-col
        class="mainButtons pl-4 pr-4 pt-0"
        xs="3"
        sm="3"
        md="3"
        lg="3"
        xl="3"
      >
        <v-card
          elevation="2"
          color="grey lighten-3"
          style="height: 46vh; width: 18vw"
          class="pt-2"
        >
          <v-row
            v-if="$i18n.locale == 'en' && userServices.advPortal"
            class="mt-2 justify-center"
          >
            <v-select
              background-color="#FAFAFA"
              class="ml-10 mr-10"
              :items="views"
              v-model="selectedView"
              @change="changeView(selectedView)"
              :label="$t('button.chooseView')"
              dense
              outlined
            ></v-select>
          </v-row>

          <!--Disabled "Change View" button -->
          <!-- <v-row
            v-if="$i18n.locale == 'en' && userServices.limitedPortal"
            class="mt-2 justify-center"
          >
            <v-select
              background-color="#FAFAFA"
              class="ml-10 mr-10 blurrEffectSoft"
              :items="views"
              v-model="selectedView"
              @change="changeView(selectedView)"
              :label="$t('button.chooseView')"
              dense
              disabled
              outlined
            ></v-select>
          </v-row> -->

          <!-- <v-row
            v-if="$i18n.locale == 'pt' && userServices.limitedPortal"
            class="mt-2 justify-center"
          >
            <v-select
              background-color="#FAFAFA"
              class="ml-10 mr-10 blurrEffectSoft"
              :items="views"
              v-model="selectedView"
              @change="changeView(selectedView)"
              :label="$t('button.chooseView')"
              dense
              disabled
              outlined
            ></v-select>
          </v-row> -->

          <v-row
            v-if="$i18n.locale == 'pt' && userServices.advPortal"
            class="mt-2 justify-center"
          >
            <v-select
              background-color="#FAFAFA"
              class="ml-10 mr-10"
              :items="viewsPt"
              v-model="selectedView"
              @change="changeView(selectedView)"
              :label="$t('button.chooseView')"
              dense
              outlined
            ></v-select>
          </v-row>
          <v-row class="justify-center" v-if="!view">
            <v-select
              background-color="#FAFAFA"
              class="ml-10 mr-10 mt-4"
              v-model="selectedDevice1"
              :items="devicesToSelect"
              @change="updateSelectedDevice(selectedDevice1)"
              :label="$t('button.chooseDevice')"
              dense
              outlined
            ></v-select>
          </v-row>

          <v-row class="justify-center" v-if="view">
            <v-select
              background-color="#FAFAFA"
              v-if="view"
              class="ml-10 mr-10"
              :items="floorsToChoose"
              v-model="floor"
              @change="blueprintByFloor()"
              :label="$t('button.floorView')"
              dense
              outlined
            ></v-select>
          </v-row>
          <v-row v-if="!view" class="justify-center ma-0">
            <v-spacer v-if="$i18n.locale == 'en'"
              ><div style="font-size: 1.1vw">Device Status:</div></v-spacer
            >
            <v-spacer v-if="$i18n.locale == 'pt'">
              <div style="font-size: 1.1vw">
                Estado do Dispositivo:
              </div></v-spacer
            >
          </v-row>
          <v-row v-if="!view" class="justify-center ma-0" align="center">
            <v-card
              outlined
              color="#FAFAFA"
              class="ml-10 mr-10"
              style="
                height: 4vh;
                width: 15vw;
                border-width: 1px;
                border-color: #757575;
                color: #424242;
              "
            >
              <v-col>
                <v-row class="justify-center">
                  <div style="font-size: 1.1vw" class="mt-1">
                    {{ updatedDeviceName }}
                  </div>
                  <div v-if="!deviceStatus" class="led-box mt-2">
                    <div class="led-red"></div>
                  </div>
                  <div v-if="deviceStatus" class="led-box mt-2">
                    <div class="led-green"></div>
                  </div>
                </v-row> </v-col
            ></v-card>
          </v-row>

          <v-row class="justify-center">
            <v-spacer v-if="$i18n.locale == 'en'" class="mt-0 ma-0"
              ><div style="font-size: 1.1vw">Last Reading:</div></v-spacer
            >
            <v-spacer v-if="$i18n.locale == 'pt'" class="mt-0 ma-0"
              ><div style="font-size: 1.1vw">Última Leitura:</div></v-spacer
            >
          </v-row>
          <v-row class="justify-center">
            <v-card
              outlined
              color="#FAFAFA"
              style="
                height: 8vh;
                width: 10vw;
                border-width: 1px;
                border-color: #757575;
                color: #424242;
              "
            >
              <div class="mt-1" style="font-size: 1.1vw">{{ time }}</div>
            </v-card>
          </v-row>
          <v-row
            class="justify-center ma-0"
            v-if="view && (userRole === 'admin' || userRole === 'master')"
          >
            <v-btn
              class="d-flex mt-6"
              elevation="2"
              @click="navigateTo({ name: 'blueprintDisplay' })"
            >
              {{ $t("button.editBlueprint") }}
            </v-btn>
          </v-row>
        </v-card>
      </v-col>

      <!-- BLUEPRINT -->
      <v-col
        v-if="view"
        class="blueprint pa-0 pr-4"
        xs="9"
        sm="9"
        md="9"
        lg="9"
        xl="9"
      >
        <v-card elevation="2" color="grey lighten-3" style="height: 46vh">
          <canva-obj
            v-if="userServices.advPortal && view && blueprintId"
            obj
            ref="canvaObj"
            :path="path"
            :blueprintId="blueprintId"
          ></canva-obj>
        </v-card>
      </v-col>

      <!-- DASHBOARD -->
      <v-col
        v-if="!view"
        class="dashboard pa-0 pr-4"
        xs="9"
        sm="9"
        md="9"
        lg="9"
        xl="9"
      >
        <v-card
          md="9"
          lg="9"
          xl="9"
          elevation="2"
          color="grey lighten-3"
          style="height: 46vh"
          class="pt-0"
        >
          <div
            v-if="
              (userServices.limitedPortal || userServices.advPortal) &&
              userServices.cards8 &&
              !view
            "
          >
            <v-row style="height: 14vh" class="ma-0">
              <v-col class="pr-1" md="3" lg="3" xl="3">
                <dashboard-card
                  v-bind:value="temperature"
                  parameter="Temperature"
                  unit="ºC"
                  path="temp.png"
                  :event="showEvents.temperature"
                />
              </v-col>
              <v-col class="pl-1 pr-1" md="3" lg="3" xl="3">
                <dashboard-card
                  :value="co2"
                  parameter="Carbon Dioxide"
                  unit="ppm"
                  path="co2.png"
                  :event="showEvents.co2"
                />
              </v-col>
              <v-col class="pr-1 pl-1" md="3" lg="3" xl="3">
                <dashboard-card
                  :value="noise"
                  parameter="Noise"
                  unit="dB"
                  path="noise.png"
                  :event="showEvents.noise"
                />
              </v-col>
              <v-col class="pl-1" md="3" lg="3" xl="3">
                <dashboard-card
                  :value="humidity"
                  parameter="Humidity"
                  unit="%"
                  path="humi.png"
                  :event="showEvents.humidity"
                />
              </v-col>
            </v-row>
            <v-row style="height: 14vh" class="ma-0">
              <v-col class="mt-1 pr-1" md="3" lg="3" xl="3">
                <dashboard-card
                  :value="lux"
                  parameter="Light Intensity"
                  unit="lux"
                  path="intluz.png"
                  :event="showEvents.light"
                />
              </v-col>
              <v-col class="mt-1 pl-1 pr-1" md="3" lg="3" xl="3">
                <dashboard-card
                  :value="tvoc"
                  parameter="Air Quality"
                  unit="ppb"
                  path="qualar.png"
                  :event="showEvents.air"
                />
              </v-col>
              <v-col class="mt-1 pl-1 pr-1" md="3" lg="3" xl="3">
                <dashboard-card
                  :value="pressure"
                  parameter="Pressure"
                  unit="hPa"
                  path="preatm.png"
                  :event="showEvents.pressure"
                />
              </v-col>
              <v-col class="mt-1 pl-1" md="3" lg="3" xl="3">
                <dashboard-card
                  :value="wifi"
                  parameter="Wifi Signal"
                  unit="dBm"
                  path="wifi.png"
                  :event="showEvents.wifi"
                />
              </v-col>
            </v-row>
            <v-row
              v-if="userServices.cards12"
              style="height: 14vh"
              class="ma-0"
            >
              <v-col class="mt-2 pr-1" md="3" lg="3" xl="3">
                <dashboard-card
                  v-if="userServices.cards12"
                  :value="r"
                  :value1="g"
                  :value2="b"
                  parameter="Light Colorimetry"
                  unit="r"
                  unit1="g"
                  unit2="b"
                  path="colo.png"
                  :event="{}"
                />
              </v-col>
              <v-col class="mt-2 pr-1 pl-1" md="3" lg="3" xl="3">
                <dashboard-card
                  v-if="userServices.cards12"
                  :value="lightTemp"
                  parameter="Light Temperature"
                  unit="ºK"
                  path="temluz.png"
                  :event="showEvents.lightTemp"
                />
              </v-col>
              <v-col class="mt-2 pr-1 pl-1" md="3" lg="3" xl="3">
                <dashboard-card
                  v-if="userServices.cards12"
                  :value="devicesCount"
                  parameter="Number of Devices"
                  unit="un"
                  path="numpes.png"
                  :event="showEvents.deviceCount"
                />
              </v-col>
              <v-col class="mt-2 pl-1" md="3" lg="3" xl="3">
                <dashboard-card
                  v-if="userServices.cards12"
                  :value="devicesFlow"
                  parameter="Devices Flow"
                  unit="un"
                  path="flupes.png"
                  :event="showEvents.peopleFlow"
                />
              </v-col>
            </v-row>

            <!-- LOCKED CARDS FOR INDIVIDUAL PACKAGES -->
            <!-- USAGE IS ONLY TO DISPLAY CARDS THAT ARE LOCKED DUE TO PACKAGE LEVEL -->
            <v-row style="height: 14vh" class="ma-0">
              <v-col class="mt-2 pr-1" md="3" lg="3" xl="3">
                <dashboard-card
                  class="blurrEffect"
                  style=""
                  v-if="userServices.limitedPortal && !userServices.cards12"
                  :value="locked"
                  :value1="null"
                  :value2="null"
                  parameter="Light Colorimetry"
                  unit=""
                  unit1=""
                  unit2=""
                  path="colo.png"
                  :event="{}"
                />
              </v-col>
              <v-col class="mt-2 pr-1 pl-1" md="3" lg="3" xl="3">
                <dashboard-card
                  class="blurrEffect"
                  v-if="userServices.limitedPortal && !userServices.cards12"
                  :value="locked"
                  parameter="Light Temperature"
                  unit=""
                  path="temluz.png"
                  :event="showEvents.lightTemp"
                />
              </v-col>
              <v-col class="mt-2 pr-1 pl-1" md="3" lg="3" xl="3">
                <dashboard-card
                  class="blurrEffect"
                  v-if="userServices.limitedPortal && !userServices.cards12"
                  :value="locked"
                  parameter="Number of Devices"
                  unit=""
                  path="numpes.png"
                  :event="showEvents.deviceCount"
                />
              </v-col>
              <v-col class="mt-2 pl-1" md="3" lg="3" xl="3">
                <dashboard-card
                  class="blurrEffect"
                  v-if="userServices.limitedPortal && !userServices.cards12"
                  :value="locked"
                  parameter="Devices Flow"
                  unit=""
                  path="flupes.png"
                  :event="showEvents.peopleFlow"
                />
              </v-col>
            </v-row>

            <!-- END OF DISPLAY -->
          </div>
        </v-card>
      </v-col>
    </v-row>

    <!--DASHBOARD BOTTOM ROW-->
    <v-row class="botObj mt-8">
      <!-- BAR GRAPH-->
      <v-col
        v-if="userServices.advPortal && view"
        class="barGraphButtons pl-4 pr-4 pt-0"
        md="3"
        lg="3"
        xl="3"
      >
        <v-card
          elevation="2"
          color="grey lighten-3"
          style="height: 46vh; width: 18vw"
          class="mt-3"
        >
          <v-row class="justify-center">
            <v-select
              background-color="#FAFAFA"
              class="ml-10 mr-10 mt-4"
              v-if="$i18n.locale == 'en'"
              :items="deviceGroups"
              v-model="deviceGroup"
              @change="selectedDeviceGroup()"
              :label="$t('button.chooseGroupOfDevices')"
              dense
              outlined
            ></v-select>
          </v-row>

          <v-row class="justify-center">
            <v-select
              background-color="#FAFAFA"
              class="ml-10 mr-10 mt-4"
              v-if="$i18n.locale == 'pt'"
              :items="deviceGroups"
              v-model="deviceGroup"
              @change="selectedDeviceGroup()"
              :label="$t('button.chooseGroupOfDevices')"
              dense
              outlined
            ></v-select>
          </v-row>

          <v-row class="justify-center">
            <v-select
              background-color="#FAFAFA"
              class="ml-10 mr-10"
              v-if="$i18n.locale == 'en'"
              :items="dataToSelect"
              v-model="dataType"
              @change="updateBarGraph(dataType, false)"
              :label="$t('button.chooseDataType')"
              dense
              outlined
            ></v-select>
          </v-row>

          <v-row class="justify-center">
            <v-select
              background-color="#FAFAFA"
              class="ml-10 mr-10"
              v-if="$i18n.locale == 'pt'"
              :items="dataToSelectPt"
              v-model="dataType"
              @change="updateBarGraph(dataType, false)"
              :label="$t('button.chooseDataType')"
              dense
              outlined
            ></v-select>
          </v-row>
          <v-row v-if="$i18n.locale == 'en'" class="justify-center">
            <v-btn
              class="d-flex mt-6"
              elevation="2"
              @click="navigateTo({ name: 'deviceSettings' })"
            >
              {{ $t("button.manageGroups") }}
            </v-btn>
          </v-row>
          <v-row v-if="$i18n.locale == 'pt'" class="justify-center">
            <v-btn
              class="d-flex mt-6"
              elevation="2"
              @click="navigateTo({ name: 'deviceSettings' })"
            >
              {{ $t("button.manageGroups") }}
            </v-btn>
          </v-row>
        </v-card>
      </v-col>

      <v-col
        v-if="view"
        class="barGraph pa-0 pr-4"
        xs="9"
        sm="9"
        md="9"
        lg="9"
        xl="9"
      >
        <v-card elevation="2" color="grey lighten-3" style="height: 46vh">
          <v-col v-if="$i18n.locale == 'en'">
            <bar-graph
              @event="barGraphEventHandler"
              :devices="barChartDevices"
              :seriesData="this.barChartSeriesData"
              :date="barChartDates"
              :dataType="this.dataType + ' by Group of Devices'"
              :measure="this.measure"
              :rotation="this.rotate"
            />
          </v-col>
          <v-col v-if="$i18n.locale == 'pt'">
            <bar-graph
              @event="barGraphEventHandler"
              :devices="barChartDevices"
              :seriesData="this.barChartSeriesData"
              :date="barChartDates"
              :dataType="this.dataType + ' por Grupo de Dispositivos'"
              :measure="this.measure"
              :rotation="this.rotate"
            />
          </v-col>
        </v-card>
      </v-col>

      <!-- LINE GRAPH-->
      <v-col
        v-if="(userServices.advPortal || userServices.limitedPortal) && !view"
        class="lineGraphButtons pl-4 pr-4 pt-0"
        md="3"
        lg="3"
        xl="3"
      >
        <v-card
          elevation="2"
          color="grey lighten-3"
          class="mt-3"
          style="height: 46vh; width: 18vw"
        >
          <v-row class="justify-center">
            <v-select
              background-color="#FAFAFA"
              class="ml-10 mr-10 mt-4"
              v-if="$i18n.locale == 'en' && userServices.advPortal"
              :items="dataToSelect"
              v-model="dataType"
              @change="updateLineGraph(dataType)"
              :label="$t('button.chooseDataType')"
              dense
              outlined
            ></v-select>
          </v-row>

          <v-row class="justify-center">
            <v-select
              background-color="#FAFAFA"
              class="ml-10 mr-10 mt-10"
              v-if="$i18n.locale == 'en' && userServices.limitedPortal"
              :items="dataToSelectLimited"
              v-model="dataType"
              @change="updateLineGraph(dataType)"
              :label="$t('button.chooseDataType')"
              dense
              outlined
            ></v-select>
          </v-row>

          <v-row class="justify-center">
            <v-select
              background-color="#FAFAFA"
              class="ml-10 mr-10 mt-10"
              v-if="$i18n.locale == 'pt' && userServices.limitedPortal"
              :items="dataToSelectPtLimited"
              v-model="dataType"
              @change="updateLineGraph(dataType)"
              :label="$t('button.chooseDataType')"
              dense
              outlined
            ></v-select>
          </v-row>

          <v-row class="justify-center">
            <v-select
              background-color="#FAFAFA"
              class="ml-10 mr-10 mt-4"
              v-if="$i18n.locale == 'pt' && userServices.advPortal"
              :items="dataToSelectPt"
              v-model="dataType"
              @change="updateLineGraph(dataType)"
              :label="$t('button.chooseDataType')"
              dense
              outlined
            ></v-select>
          </v-row>

          <v-row
            class="justify-center"
            v-if="$i18n.locale == 'en' && userServices.advPortal && userServices.feeBasic"
          >
            <v-select
              background-color="#FAFAFA"
              class="ml-10 mr-10"
              :items="periodsToSelect"
              v-model="timePeriod"
              @change="updateTimePeriod(timePeriod)"
              :label="$t('button.chooseTimePeriod')"
              dense
              outlined
            ></v-select>
          </v-row>

          <v-row
            class="justify-center"
            v-if="$i18n.locale == 'pt' && userServices.advPortal && userServices.feeBasic"
          >
            <v-select
              background-color="#FAFAFA"
              class="ml-10 mr-10"
              :items="periodsToSelectPt"
              v-model="timePeriod"
              @change="updateTimePeriod(timePeriod)"
              :label="$t('button.chooseTimePeriod')"
              dense
              outlined
            ></v-select>
          </v-row>

          <!--Disabled "Choose Time Period button -->
          <!-- <v-row
            class="justify-center blurrEffectSoft"
            v-if="$i18n.locale == 'en' && userServices.limitedPortal"
          >
            <v-select
              background-color="#FAFAFA"
              class="ml-10 mr-10"
              :items="periodsToSelect"
              v-model="timePeriod"
              @change="updateTimePeriod(timePeriod)"
              :label="$t('button.chooseTimePeriod')"
              dense
              disabled
              outlined
            ></v-select>
          </v-row> -->

          <!-- <v-row
            class="justify-center blurrEffectSoft"
            v-if="$i18n.locale == 'pt' && userServices.limitedPortal"
          >
            <v-select
              background-color="#FAFAFA"
              class="ml-10 mr-10"
              :items="periodsToSelectPt"
              v-model="timePeriod"
              @change="updateTimePeriod(timePeriod)"
              :label="$t('button.chooseTimePeriod')"
              dense
              disabled
              outlined
            ></v-select>
          </v-row> -->

          <!-- DATE PICKER -->

          <v-row align="center" justify="center" v-if="userServices.advPortal && userServices.feeBasic">
            <v-card outlined style="border-width: 1px; border-color: #bdbdbd">
              <v-col cols="12" sm="11" md="11" xl="11" class="pa-0">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="dateRange"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateRange"
                      :label="$t('button.dateRange')"
                      persistent-hint
                      prepend-icon="date_range"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="dateRange" range no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.menu.save(dateRange);
                        passDateRangeToLineGraph();
                      "
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-card>
          </v-row>

          <!-- DATE PICKER DISABLED-->

          <!-- <v-row
            align="center"
            justify="center"
            class="blurrEffectSoft"
            v-if="userServices.limitedPortal"
          >
            <v-card outlined style="border-width: 1px; border-color: #bdbdbd">
              <v-col cols="12" sm="11" md="11" lg="11" xl="11" class="pa-0">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="dateRange"
                  transition="scale-transition"
                  offset-y
                  disabled
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateRange"
                      :label="$t('button.dateRange')"
                      persistent-hint
                      disabled
                      prepend-icon="date_range"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="dateRange" range no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      disabled
                      color="primary"
                      @click="
                        $refs.menu.save(dateRange);
                        passDateRangeToLineGraph();
                      "
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-card>
          </v-row> -->
        </v-card>
      </v-col>

      <v-col
        v-if="!view"
        class="lineGraph pa-0 pr-4"
        xs="9"
        sm="9"
        md="9"
        lg="9"
        xl="9"
      >
        <v-card elevation="2" color="grey lighten-3" style="height: 46vh">
          <v-col v-if="$i18n.locale == 'en'">
            <line-graph
              :dateList="dates"
              :valueList="series"
              :dataType="this.dataType + ' Evolution Over Time'"
              :measure="this.measure"
            />
          </v-col>
          <v-col v-if="$i18n.locale == 'pt'">
            <line-graph
              :dateList="dates"
              :valueList="series"
              :dataType="this.dataType + ' ao Longo do Tempo'"
              :measure="this.measure"
            />
          </v-col>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DashboardCard from "@/components/DashboardCard";
import LineGraph from "@/components/LineGraph";
import BarGraph from "../../components/BarGraph.vue";
import DataService from "@/services/DataService";
import EventService from "@/services/EventService";
import DeviceGroupService from "@/services/DeviceGroupService";
import HelperMethods from "@/utilities/HelperMethods";
import CanvaObj from "@/components/CanvaObj";
import moment from "moment";
import { mapState } from "vuex";
import * as echarts from "echarts";

export default {
  components: {
    DashboardCard,
    LineGraph,
    BarGraph,
    CanvaObj,
  },
  data() {
    return {
      // Global Variables
      interval: null,
      view: null,
      payload: {},
      views: ["Global", "By SensorBlock"],
      viewsPt: ["Global", "Por SensorBlock"],
      selectedView: null,
      devices: [],
      selectedDevices: [],
      deviceName: "",
      barChart: true,
      lineChart: false,
      error: null,
      deviceIsOnline: null,

      // Blueprint Variables
      floorsToChoose: [],
      floor: null,
      blueprintsObj: null,
      blueprintId: null,
      path: "",

      // Event Variables
      events: {},
      userServices: {},
      showEventsBlueprint: {
        temperature: {
          flag: false,
          severity: null,
          message: "",
        },
        co2: {
          flag: false,
          severity: null,
          message: "",
        },
        noise: {
          flag: false,
          severity: null,
          message: "",
        },
        humidity: {
          flag: false,
          severity: null,
          message: "",
        },
        light: {
          flag: false,
          severity: null,
          message: "",
        },
        air: {
          flag: false,
          severity: null,
          message: "",
        },
        pressure: {
          flag: false,
          severity: null,
          message: "",
        },
        wifi: {
          flag: false,
          severity: null,
          message: "",
        },
        deviceCount: {
          flag: false,
          severity: null,
          message: "",
        },
        lightTemp: {
          flag: false,
          severity: null,
          message: "",
        },
        peopleFlow: {
          flag: false,
          severity: null,
          message: "",
        },
        disconnect: {
          flag: false,
          severity: null,
          message: "",
        },
        reconnect: {
          flag: false,
          severity: null,
          message: "",
        }
      },
      showEvents: {
        temperature: {
          flag: false,
          severity: null,
          message: "",
        },
        co2: {
          flag: false,
          severity: null,
          message: "",
        },
        noise: {
          flag: false,
          severity: null,
          message: "",
        },
        humidity: {
          flag: false,
          severity: null,
          message: "",
        },
        light: {
          flag: false,
          severity: null,
          message: "",
        },
        air: {
          flag: false,
          severity: null,
          message: "",
        },
        pressure: {
          flag: false,
          severity: null,
          message: "",
        },
        wifi: {
          flag: false,
          severity: null,
          message: "",
        },
        deviceCount: {
          flag: false,
          severity: null,
          message: "",
        },
        lightTemp: {
          flag: false,
          severity: null,
          message: "",
        },
        peopleFlow: {
          flag: false,
          severity: null,
          message: "",
        },
        disconnect: {
          flag: false,
          severity: null,
          message: "",
        },
        reconnect: {
          flag: false,
          severity: null,
          message: "",
        }
      },

      //Graph Variables
      series: [],
      dates: [],
      barChartPayload: [],
      barChartDates: [],
      barChartSeriesData: [],
      barChartDevices: [],
      groupDeviceGroups: [],
      deviceGroups: [],
      deviceGroup: "",
      measure: "",
      rotate: 0,

      // Button variables
      selectedDevice: [],
      selectedDevice1: [],
      devicesToSelect: [],
      periodsToSelect: ["Daily", "Weekly", "Monthly"],
      periodsToSelectPt: ["Diário", "Semanal", "Mensal"],
      dataToSelect: [
        "Temperature",
        "Carbon Dioxide",
        "Noise",
        "Humidity",
        "Light Intensity",
        "Air Quality",
        "Pressure",
        "Wifi Signal",
        "Light Temperature",
        "Number of Devices",
        "People Flow",
      ],
      dataToSelectLimited: [
        "Temperature",
        "Carbon Dioxide",
        "Noise",
        "Humidity",
        "Light Intensity",
        "Air Quality",
        "Pressure",
        "Wifi Signal",
      ],
      dataToSelectPt: [
        "Temperatura",
        "Dióxido de Carbono",
        "Ruído",
        "Humidade",
        "Intensidade da Luz",
        "Qualidade do Ar",
        "Pressão",
        "Sinal Wifi",
        "Temperatura da Luz",
        "Número de Dispositivos",
        "Fluxo de Pessoas",
      ],
      dataToSelectPtLimited: [
        "Temperatura",
        "Dióxido de Carbono",
        "Ruído",
        "Humidade",
        "Intensidade da Luz",
        "Qualidade do Ar",
        "Pressão",
        "Sinal Wifi",
      ],
      graphsToSelect: ["Line", "Bar"],
      graphsToSelectPt: ["Linha", "Barra"],
      dataTypes: [
        "temp",
        "eco2",
        "dbavg",
        "humidity",
        "lux",
        "tvoc",
        "pressure",
        "rssi",
        "devicesNumber",
      ],
      selectedGraph: "",
      dataType: "",
      dateFrom: "",
      dateTo: "",
      timePeriod: "",

      // Calendar Variables
      dateRange: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],
      menu: false,
    };
  },

  computed: {
    ...mapState(["userRole"]),
    resolution() {
      if (this.$vuetify.breakpoint.width < 1264) {
        return "motherSmallRes";
      } else return "mother";
    },
    temperature() {
      return this.payload?.data?.temp;
    },
    noise() {
      return this.payload?.data?.dbavg;
    },
    humidity() {
      return this.payload?.data?.humidity;
    },
    co2() {
      return this.payload?.data?.eco2;
    },
    lux() {
      return this.payload?.data?.lux;
    },
    wifi() {
      return this.payload?.data?.rssi;
    },
    tvoc() {
      return this.payload?.data?.tvoc;
    },
    pressure() {
      return this.payload?.data?.pressure;
    },
    lightTemp() {
      return this.payload?.data?.light_temp;
    },
    r() {
      return this.payload?.data?.r;
    },
    g() {
      return this.payload?.data?.g;
    },
    b() {
      return this.payload?.data?.b;
    },
    devicesCount() {
      return this.payload?.data?.devicesCount;
    },
    devicesFlow() {
      return this.payload?.data?.devicesFlow;
    },
    time() {
      if (
        this.$i18n.locale == "pt" &&
        !(this.payload?.data?.createdAt === undefined)
      ) {
        return moment(this.payload?.data?.createdAt)
          .locale("pt")
          .format("Do MMMM YYYY h:mm:ss a");
      } else if (
        this.$i18n.locale == "en" &&
        !(this.payload?.data?.createdAt === undefined)
      ) {
        return moment(this.payload?.data?.createdAt).format(
          "Do MMMM YYYY h:mm:ss a"
        );
      } else if (this.$i18n.locale == "pt") {
        return "Sem Dados";
      } else return "No Data";
    },

    updatedDeviceName() {
      return this.deviceName;
    },

    deviceStatus() {
      return this.deviceIsOnline;
    },

    locked() {
      return "Locked";
    },
  },

  methods: {
    navigateTo(route) {
      this.$router.push(route);
    },

    updateDeviceStatus() {
      let services = this.$store.state.userServices;
      var sr = HelperMethods.getGroupSampleRate(services);

      if (this.payload?.data?.createdAt === null) {
        return (this.deviceIsOnline = false);
      }

      let startTime = moment(this.payload?.data?.createdAt);
      let endTime = moment();
      if (endTime.diff(startTime, "seconds") >= sr) {
        return (this.deviceIsOnline = false);
      } else return (this.deviceIsOnline = true);
    },

    changeView(selectedView) {
      if (selectedView == "Global") {
        this.view = true;
        this.updateSelectedGraph("Bar");
        return;
      } else if (selectedView == "By SensorBlock") {
        this.view = false;
        this.updateSelectedGraph("Line");
        return;
      } else if (selectedView == "Global") {
        this.view = true;
        this.updateSelectedGraph("Barra");
        return;
      } else if (selectedView == "Por SensorBlock") {
        this.view = false;
        this.updateSelectedGraph("Linha");
      } else return null;
    },

    blueprintByFloor() {
      let self = this;
      let path = HelperMethods.getBlueprintPathByFloorName(
        this.blueprintsObj,
        this.floor
      );
      let blueprintId = HelperMethods.getBlueprintIdByFloorName(
        this.blueprintsObj,
        this.floor
      );
      this.path = path;
      this.blueprintId = blueprintId;

      // Building an array of objects that store the device id and status
      let devicesToHighlight = [];
      for(let i = 0; i < this.barChartPayload.length; i++) {
        let device = {};
        device.id = self.barChartPayload[i].refDeviceId;
        device.status = self.barChartPayload[i].online;
        devicesToHighlight.push(device);
      }

      // HIGHLIGHTING THE PROPER DEVICES
      // Ask the CanvaObj to highlight the devices if they belong to the refBlueprint active
      this.$refs.canvaObj.highlightDevicesById(
        devicesToHighlight,
        blueprintId
      );
    },

    async getDashboardData(devices) {
      try {
        await DataService.getCurrentData(devices[0]).then((data) => {
          this.payload = data?.data;
        });
      } catch (err) {
        console.log(err);
      }
    },

    async getDashboardEvents(dataId, deviceId) {
      let self = this;
      try {
        await EventService.getCurrentEvents(dataId, deviceId).then((data) => {
          this.events = data.data;
        });
      } catch (err) {
        this.error = "An error occurred while loading data";
        console.log(err);
      }
      // resetting showEvents variable to its blueprint
      this.showEvents = { ...this.showEventsBlueprint };

      this.events.forEach(function (value) {
        switch (value.dataType) {
          case 0:
            self.showEvents.temperature = {};
            self.showEvents.temperature.flag = true;
            self.showEvents.temperature.severity = value.eventType;
            self.showEvents.temperature.message = value.message;
            break;
          case 1:
            self.showEvents.co2 = {};
            self.showEvents.co2.flag = true;
            self.showEvents.co2.severity = value.eventType;
            self.showEvents.co2.message = value.message;
            break;
          case 2:
            self.showEvents.noise = {};
            self.showEvents.noise.flag = true;
            self.showEvents.noise.severity = value.eventType;
            self.showEvents.noise.message = value.message;
            break;
          case 3:
            self.showEvents.humidity = {};
            self.showEvents.humidity.flag = true;
            self.showEvents.humidity.severity = value.eventType;
            self.showEvents.humidity.message = value.message;
            break;
          case 4:
            self.showEvents.light = {};
            self.showEvents.light.flag = true;
            self.showEvents.light.severity = value.eventType;
            self.showEvents.light.message = value.message;
            break;
          case 5:
            self.showEvents.air = {};
            self.showEvents.air.flag = true;
            self.showEvents.air.severity = value.eventType;
            self.showEvents.air.message = value.message;
            break;
          case 6:
            self.showEvents.pressure = {};
            self.showEvents.pressure.flag = true;
            self.showEvents.pressure.severity = value.eventType;
            self.showEvents.pressure.message = value.message;
            break;
          case 7:
            self.showEvents.wifi = {};
            self.showEvents.wifi.flag = true;
            self.showEvents.wifi.severity = value.eventType;
            self.showEvents.wifi.message = value.message;
            break;
          case 8:
            self.showEvents.deviceCount = {};
            self.showEvents.deviceCount.flag = true;
            self.showEvents.deviceCount.severity = value.eventType;
            self.showEvents.deviceCount.message = value.message;
            break;
          case 9:
            self.showEvents.lightTemp = {};
            self.showEvents.lightTemp.flag = true;
            self.showEvents.lightTemp.severity = value.eventType;
            self.showEvents.lightTemp.message = value.message;
            break;
          case 10:
            self.showEvents.peopleFlow = {};
            self.showEvents.peopleFlow.flag = true;
            self.showEvents.peopleFlow.severity = value.eventType;
            self.showEvents.peopleFlow.message = value.message;
            break;
          case 11:
            self.showEvents.disconnect = {};
            self.showEvents.disconnect.flag = true;
            self.showEvents.disconnect.severity = value.eventType;
            self.showEvents.disconnect.message = value.message;
            break;
          case 12:
            self.showEvents.reconnect = {};
            self.showEvents.reconnect.flag = true;
            self.showEvents.reconnect.severity = value.eventType;
            self.showEvents.reconnect.message = value.message;
            break;
        }
      });
    },

    passDateRangeToLineGraph() {
      if (this.dateRange.length === 1) {
        this.dateFrom = new Date(this.dateRange[0]);
        this.dateTo = new Date(Date.now());
        this.updateLineGraph(this.dataType);
        return;
      }

      let date1 = new Date(this.dateRange[0]);
      let date2 = new Date(this.dateRange[1]);

      if (date1 > date2) {
        this.dateFrom = date2;
        this.dateTo = date1;
        this.updateLineGraph(this.dataType);
        return;
      } else if (date2 > date1) {
        this.dateFrom = date1;
        this.dateTo = date2;
        this.updateLineGraph(this.dataType);
        return;
      } else {
        this.dateFrom = date1;
        this.dateTo = new Date(Date.now());
        this.updateLineGraph(this.dataType);
        return;
      }
    },

    updateLineGraph(dataType) {
      this.measure = HelperMethods.defineDataMeasure(dataType);
      let dataOfType = dataType;

      DataService.getDataOfType(
        this.selectedDevice,
        this.dateFrom,
        this.dateTo,
        dataOfType
      )
        .then((data) => {
          this.series = [];
          this.dates = [];

          let payload = data.data;
          let services = this.$store.state.userServices;

          //The block below is responsible to place null values during the periods of time the device was down
          //The null values will be represented on the graph as empty space

          var sr = HelperMethods.getGroupSampleRate(services);

          for (let i = 0; i < payload.length - 1; i++) {
            let startTime = moment(payload[i].createdAt);
            let endTime = moment(payload[i + 1].createdAt);
            if (endTime.diff(startTime, "seconds") >= sr) {
              var newDate = moment(payload[i].createdAt).add(5, "minutes");
              payload.splice(i + 1, 0, { temp: null, createdAt: newDate });
            }
          }

          //Next block formatts the timestamp to a more easy to interpret format

          payload.forEach(function (value) {
            value.createdAt = HelperMethods.formatTimestamp1(
              value["createdAt"]
            );
            value.createdAt = HelperMethods.formatTimestamp2(
              value["createdAt"]
            );
          });

          //Following code isolates the date values on the "dates" array and the data on the "series" array
          //As the graph needs to be fed with individual arrays for each axis

          this.dates = payload.map(function (item) {
            return item.createdAt;
          });

          let temp = [];
          for (let row of payload) {
            switch (dataOfType) {
              // ENGLISH
              case "Temperature":
                temp.push(row.temp);
                break;
              case "Carbon Dioxide":
                temp.push(row.eco2);
                break;
              case "Noise":
                temp.push(row.dbavg);
                break;
              case "Humidity":
                temp.push(row.humidity);
                break;
              case "Light Intensity":
                temp.push(row.lux);
                break;
              case "Air Quality":
                temp.push(row.tvoc);
                break;
              case "Pressure":
                temp.push(row.pressure);
                break;
              case "Wifi Signal":
                temp.push(row.rssi);
                break;
              case "Light Temperature":
                temp.push(row.light_temp);
                break;
              case "Number of Devices":
                temp.push(row.devicesCount);
                break;
              case "People Flow":
                temp.push(row.devicesFlow);
                break;

              // PORTUGUESE
              case "Temperatura":
                temp.push(row.temp);
                break;
              case "Dióxido de Carbono":
                temp.push(row.eco2);
                break;
              case "Ruído":
                temp.push(row.dbavg);
                break;
              case "Humidade":
                temp.push(row.humidity);
                break;
              case "Intensidade da Luz":
                temp.push(row.lux);
                break;
              case "Qualidade do Ar":
                temp.push(row.tvoc);
                break;
              case "Pressão":
                temp.push(row.pressure);
                break;
              case "Sinal Wifi":
                temp.push(row.rssi);
                break;
              case "Temperatura da Luz":
                temp.push(row.light_temp);
                break;
              case "Número de Dispositivos":
                temp.push(row.devicesCount);
                break;
              case "Fluxo de Pessoas":
                temp.push(row.devicesFlow);
                break;
              default:
                break;
            }
          }
          this.series.push({
            type: "line",
            lineStyle: {
              color: "#5470C6",
              width: 3,
            },
            showSymbol: false,
            data: temp,
            connectNulls: false,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // Takes a variable draw to determine if it is necessary to redraw the sensors on the blueprint
    // 
    async updateBarGraph(dataType, draw) {
      // Sets variable "measure" to hold the selected dataType measure (ºC, db's, %, etc) to be passed to the graph
      this.measure = HelperMethods.defineDataMeasure(dataType);

      // If the number of devices to be shown on the graph is superior to 8 then their names will be rotated to avoid overlapping
      if (this.selectedDevices.length >= 8) {
        this.rotate = 15;
      }

      let services = this.$store.state.userServices;
      let sr = HelperMethods.getGroupSampleRate(services);

      let self = this;
      let dataOfType = "";
      this.barChartDates = [];
      this.barChartSeriesData = [];
      this.barChartDevices = [];
      this.barChartSeriesData = [];
      dataOfType = HelperMethods.getDataType(dataType);

      try {
        let response = await DataService.getDataFromAllDevices(
          self.selectedDevices,
          dataOfType
        );
        this.barChartPayload = response.data;

        this.barChartPayload.forEach(function (value) {
          value.name = HelperMethods.nameDevice(
            value["refDeviceId"],
            self.devices
          );
        });

        for (let i = 0; i < this.barChartPayload.length; i++) {
          this.barChartDates.push(this.barChartPayload[i].createdAt);
          this.barChartDevices.push(this.barChartPayload[i].name);

          let startTime = moment(this.barChartPayload[i].createdAt);
          let endTime = moment();
          if (endTime.diff(startTime, "seconds") >= sr) {
            this.barChartPayload[i].online = false;
          } else this.barChartPayload[i].online = true;

          if (this.barChartPayload[i].online) {
            this.barChartSeriesData.push({
              value: this.barChartPayload[i][`${dataOfType}`],
              itemStyle: {
                color: new echarts.graphic.LinearGradient(10, 10, 10, 1, [
                  { offset: 0, color: "#83bff6" },
                  { offset: 0.5, color: "#188df0" },
                  { offset: 1, color: "#3e77b6" },
                ]),
              },
            });
          } else {
            this.barChartSeriesData.push({
              value: this.barChartPayload[i][`${dataOfType}`],
              itemStyle: {
                color: "#B0BEC5",
              },
            });
          }
        }
      } catch (err) {
        console.log(err);
      }

      if (draw) {
        // Building an array of objects that stores the device id and status
        let devicesToHighlight = [];
        for(let i = 0; i < this.barChartPayload.length; i++) {
          let device = {};
          device.id = self.barChartPayload[i].refDeviceId;
          device.status = self.barChartPayload[i].online;
          devicesToHighlight.push(device);
        }
        
        // HIGHLIGHTING THE PROPER DEVICES
        // Ask the CanvaObj to highlight the devices if they belong to the refBlueprint active
        self.$refs.canvaObj.highlightDevicesById(
          devicesToHighlight,
          this.blueprintId
        );
      }
    },

    updateSelectedGraph(selectedGraph) {
      switch (selectedGraph) {
        case "Line":
          this.barChart = false;
          this.lineChart = true;
          this.dataType = "Temperature";
          this.updateLineGraph(this.dataType);
          break;
        case "Bar":
          this.barChart = true;
          this.lineChart = false;
          this.dataType = "Temperature";
          this.updateBarGraph(this.dataType, true);
          break;
        case "Linha":
          this.barChart = false;
          this.lineChart = true;
          this.dataType = "Temperatura";
          this.updateLineGraph(this.dataType);
          break;
        case "Barra":
          this.barChart = true;
          this.lineChart = false;
          this.dataType = "Temperatura";
          this.updateBarGraph(this.dataType, true);
          break;
      }
    },

    updateTimePeriod(timePeriod) {
      this.dateTo = moment().add(1, "days").format("YYYY-MM-DD");
      switch (timePeriod) {
        case "Daily":
          this.dateFrom = moment()
            .subtract(1, "days")
            .format("YYYY-MM-DD h:mm:ss a");
          break;
        case "Weekly":
          this.dateFrom = moment()
            .subtract(1, "weeks")
            .format("YYYY-MM-DD h:mm:ss a");
          break;
        case "Monthly":
          this.dateFrom = moment()
            .subtract(1, "months")
            .format("YYYY-MM-DD h:mm:ss a");
          break;
        case "Diário":
          this.dateFrom = moment()
            .subtract(1, "days")
            .format("YYYY-MM-DD h:mm:ss a");
          break;
        case "Semanal":
          this.dateFrom = moment()
            .subtract(1, "weeks")
            .format("YYYY-MM-DD h:mm:ss a");
          break;
        case "Mensal":
          this.dateFrom = moment()
            .subtract(1, "months")
            .format("YYYY-MM-DD h:mm:ss a");
          break;
      }
      this.updateLineGraph(this.dataType);
    },

    async updateSelectedDevice(selectedDevice) {
      this.selectedDevice = [];

      for (let i = 0; i < this.devices.length; i++) {
        if (selectedDevice === this.devices[i].name) {
          this.selectedDevice.push(this.devices[i].id);
          this.deviceName = this.devices[i].name;
        }
      }
      await this.getDashboardData(this.selectedDevice);

      // If there is no data on the DB for a device then we return deviceIsOnline variable as false in order
      // to identify it as offline and stop the execution here as there is no need to advance on the next steps
      if (this.payload.data === null) {
        return (this.deviceIsOnline = false);
      }

      this.getDashboardEvents(this.payload.data.id, this.selectedDevice);
      this.updateLineGraph(this.dataType);
      this.updateDeviceStatus();
    },

    async getGroupDeviceGroups(groupId) {
      try {
        let response = await DeviceGroupService.getGroupDeviceGroups({
          params: { groupId },
        });
        this.groupDeviceGroups = response.data;
        for (let i = 0; i < response.data.length; i++) {
          this.deviceGroups.push(response.data[i].name);
        }
        // Assigning "deviceGroup" a predefined value so the v-select displays it since the component is mounted
        this.deviceGroup = this.deviceGroups[0];
      } catch (err) {
        console.log(err);
      }
    },

    // Method triggered by the ChooseDeviceGroup button. Upon selection a deviceGroup name is attributted to the variable deviceGroup.
    async selectedDeviceGroup() {
      let refDeviceGroup;
      this.barChartDates = [];
      this.barChartSeriesData = [];
      this.barChartDevices = [];
      this.selectedDevices = [];
      let self = this;

      // Loop through the global array of objects "groupDeviceGroups", which is set on "mounted" and holds all group deviceGroups objects
      // and if a match between the deviceGroup name and the groupDeviceGroups name is found return that deviceGroup id
      for (let i = 0; i < this.groupDeviceGroups.length; i++) {
        if (this.deviceGroup == this.groupDeviceGroups[i].name) {
          refDeviceGroup = this.groupDeviceGroups[i].id;
        }
      }

      try {
        // Query the database for all devices belonging to the group passed
        let response = await DeviceGroupService.getDevicesFromGroup({
          params: { refDeviceGroup },
        });

        // Push all group devices returned from the DB to the selectedDevices array
        for (let i = 0; i < response.data.length; i++) {
          self.selectedDevices.push(response.data[i].refDevice);
        }

        // Store on refBlueprint variable the ID of the current blueprint being displayed
        let refBlueprint = HelperMethods.getBlueprintIdByFloorName(
          this.blueprintsObj,
          this.floor
        );

        // If the group selected has no devices we stop the execution here
        if (response.data.length === 0) {
          // Ask the CanvaObj to reset the highlight of the devices since none is selected
          self.$refs.canvaObj.highlightDevicesById(
            this.selectedDevices,
            refBlueprint
          );
          return;
        }

        // UPDATING THE BAR GRAPH
        // If the number of devices to be shown on the graph is superior to 8 then their names will be rotated to avoid overlapping
        if (this.selectedDevices.length >= 8) {
          this.rotate = 15;
        } else this.rotate = 0;

        let services = this.$store.state.userServices;
        let sr = HelperMethods.getGroupSampleRate(services);

        // Parse the dataType name attributted to the global variable "dataType" to a name the DB understands
        let dataOfType = HelperMethods.getDataType(this.dataType);

        // Query the DB for the data of the dataOfType for all devices passed
        let response1 = await DataService.getDataFromAllDevices(
          self.selectedDevices,
          dataOfType
        );

        // Store the array of objects returned from the DB query on the data obj
        this.barChartPayload = response1.data;

        // Create a new key/value pair with key "name" on the object, and give it its device name according to its refDeviceId
        this.barChartPayload.forEach(function (value) {
          value.name = HelperMethods.nameDevice(
            value["refDeviceId"],
            self.devices
          );
        });

        for (let i = 0; i < this.barChartPayload.length; i++) {
          this.barChartDates.push(this.barChartPayload[i].createdAt);
          this.barChartDevices.push(this.barChartPayload[i].name);

          let startTime = moment(this.barChartPayload[i].createdAt);
          let endTime = moment();

          // Below we evaluate if the device is online or offline
          if (endTime.diff(startTime, "seconds") >= sr) {
            this.barChartPayload[i].online = false;
          } else this.barChartPayload[i].online = true;

          // Depending on the device status we will draw the bar on the graph as blue (online) or as grey (offline)
          if (this.barChartPayload[i].online) {
            this.barChartSeriesData.push({
              value: this.barChartPayload[i][`${dataOfType}`],
              itemStyle: {
                color: new echarts.graphic.LinearGradient(10, 10, 10, 1, [
                  { offset: 0, color: "#83bff6" },
                  { offset: 0.5, color: "#188df0" },
                  { offset: 1, color: "#3e77b6" },
                ]),
              },
            });
          } else {
            this.barChartSeriesData.push({
              value: this.barChartPayload[i][`${dataOfType}`],
              itemStyle: {
                color: "#B0BEC5",
              },
            });
          }
        }

        // Building an array of objects that store the device id and status
        let devicesToHighlight = [];
        for(let i = 0; i < this.barChartPayload.length; i++) {
          let device = {};
          device.id = self.barChartPayload[i].refDeviceId;
          device.status = self.barChartPayload[i].online;
          devicesToHighlight.push(device);
        }

        // HIGHLIGHTING THE PROPER DEVICES
        // Ask the CanvaObj to highlight the devices if they belong to the refBlueprint active
        self.$refs.canvaObj.highlightDevicesById(
          devicesToHighlight,
          refBlueprint
        );
      } catch (err) {
        console.log(err);
      }
    },

    // Method triggered upon detection of an event (click) on the Bar Graph component
    // It will highlight a certain device on the blueprint depending on the bar that has been pressed
    barGraphEventHandler(data) {
      let device = HelperMethods.getDeviceObjByName(this.devices, data.name);

      // Building an array of objects that store the device id and status
      let devicesToHighlight = [];
      for(let i = 0; i < this.barChartPayload.length; i++) {
        let device = {};
        device.id = this.barChartPayload[i].refDeviceId;
        device.status = this.barChartPayload[i].online;
        devicesToHighlight.push(device);
      }

      this.$refs.canvaObj.highlightDevicesById(
        devicesToHighlight,
        this.blueprintId,
        true,
        device.id
      );
    },

    // Method to auto update the dashboard every 1min when at the lobby page (self triggered)
    async updateDashboard() {
      let self = this;
      this.interval = setInterval(async function(){
        console.log("dashboard update")
        await self.getDashboardData(self.selectedDevice);
        await self.getDashboardEvents(self.payload.data.id, self.selectedDevice);
      }, 60000);
    }
  },

  destroyed() {
    // Method to stop the auto update dashboard
    clearInterval(this.interval);
  },

  async mounted() {
    let self = this;

    // Loading user permissions
    this.userServices = this.$store.getters.userServices;
    // LIMITED PORTAL //

    // Assigning "selectedView" a predefined value so the v-select displays it since the component is mounted
    if (this.$i18n.locale == "en" && this.userServices.advPortal === false) {
      this.selectedView = "By SensorBlock";
    } else if (
      this.$i18n.locale == "pt" &&
      this.userServices.advPortal === false
    ) {
      this.selectedView = "Por SensorBlock";
    } else if (
      this.$i18n.locale == "en" &&
      this.userServices.advPortal === true
    ) {
      this.selectedView = "Global";
    } else if (
      this.$i18n.locale == "pt" &&
      this.userServices.advPortal === true
    ) {
      this.selectedView = "Global";
    }

    // Verifying if the user has the limitedPortal service && setting dashboard view accordingly
    if (this.userServices.advPortal === false) {
      this.view = false;
    } else {
      this.view = true;
    }

    // ADVANCED PORTAL //

    // Verifying if the user has the advPortal service
    if (this.userServices.advPortal === true) {
      // Setting up default blueprint to show
      // Get all floor blueprints, from the store, uploaded by the group to make them available to be displayed
      this.blueprintsObj = this.$store.getters.blueprints;
    }

    if (this.blueprintsObj != null && this.blueprintsObj.length != 0) {
      this.floorsToChoose = HelperMethods.getFloorNamesFromBlueprintObj(
        this.blueprintsObj
      );

      // Assigning "floor" a predefined value so the v-select displays it since the component is mounted
      this.floor = this.floorsToChoose[0];
      // Default blueprint to display
      this.path = this.blueprintsObj[0].path;
      this.blueprintId = Number(this.blueprintsObj[0].id);
    }

    // LIMITED && ADVANCED PORTAL

    // Setting up the default device from whom data will be shown
    this.devices = this.$store.getters.devices;
    for (let i = 0; i < this.devices.length; i++) {
      self.selectedDevices.push(self.devices[i].id);
    }
    this.selectedDevice.push(this.devices[0].id);

    // Assigning "selectedDevice1" a predifined value so v-select displays it since the component is mounted
    this.selectedDevice1 = this.devices[0].name;

    // Setting up "rotate" variable for barChart names legend rotation (advPortal)
    // If the number of devices to be shown on the graph is superior to 8 then their names will be rotated to avoid overlapping
    if (this.selectedDevices.length >= 8) {
      this.rotate = 15;
    }

    this.deviceName = this.devices[0].name;

    // Setting the default values for time period (daily)
    this.dateFrom = moment().subtract(1, "days").format("YYYY-MM-DD h:mm:ss a");
    this.dateTo = moment().add(0, "days").format("YYYY-MM-DD h:mm:ss a");

    // Assigning "timePeriod" a predifined value so v-select displays it since the component is mounted
    if (this.$i18n.locale === "en") {
      this.timePeriod = "Daily";
    } else if (this.$i18n.locale === "pt") {
      this.timePeriod = "Diário";
    }

    // Storing the names of the devices on an array to have them on hand to pass to the "Choose Device button"
    for (let i = 0; i < this.devices.length; i++) {
      this.devicesToSelect.push(this.devices[i].name);
    }

    // Setting default data type to be shown on the line graph and the default device name to be displayed
    if (this.$i18n.locale === "pt") {
      this.dataType = "Temperatura";
    } else this.dataType = "Temperature";

    this.updateDashboard();

    // DATABASE QUERYING //

    try {
      // Querying the database for latest data entry to populate the dashboard
      await this.getDashboardData(this.selectedDevice);

      // Check and display default device selected status
      this.updateDeviceStatus();

      // Querying events db table for all events with refData = data.id
      await this.getDashboardEvents(this.payload.data.id, this.selectedDevice);

      // Calling updateLineGraph to fetch the necessary data to plot the line graph
      this.updateLineGraph(this.dataType);

      // Set the data to plot the bar chart
      if (this.userServices.advPortal) {
        let groupId = this.$store.getters.groupId;

        // Get all deviceGroups from the group to populate the choose device group combobox button of the bar chart
        await this.getGroupDeviceGroups(groupId);

        var deviceIds = [];
        if (this.groupDeviceGroups.length > 0) {
          let refDeviceGroup = this.groupDeviceGroups[0].id;
          let groupDevices = await DeviceGroupService.getDevicesFromGroup({
            params: { refDeviceGroup },
          });

          groupDevices.data.forEach(function (value) {
            deviceIds.push(Number(value.refDevice));
          });
        } else {
          this.devices.forEach(function (value) {
            deviceIds.push(Number(value.id));
          });
        }
        // Setting up the selectedDevices predetermined value, of all devices,
        // to the value of the deviceIds array. selectedDevices is being used by the
        // blueprintByFloor method to highlight the proper devices on the chosen blueprint
        this.selectedDevices = deviceIds;

        // HIGHLIGHTING THE PROPER DEVICES
        // Store on refBlueprint variable the ID of the current blueprint being displayed
        let refBlueprint = HelperMethods.getBlueprintIdByFloorName(
          this.blueprintsObj,
          this.floor
        );

        // Calculate the sampleRate + margin (sr) to determine if the device is online or offline
        // If it is offline its data will be shown with a grey color on the bar graph
        let services = this.$store.state.userServices;
        var sr = HelperMethods.getGroupSampleRate(services);

        // Get the data to plot the Bar Graph
        let dataType = "temp";
        let response = await DataService.getDataFromAllDevices(
          deviceIds,
          dataType
        );
        this.barChartPayload = response.data;

        this.barChartPayload.forEach(function (value) {
          value.name = HelperMethods.nameDevice(
            value["refDeviceId"],
            self.devices
          );
        });
        

        for (let i = 0; i < this.barChartPayload.length; i++) {
          this.barChartDates.push(this.barChartPayload[i].createdAt);
          this.barChartDevices.push(this.barChartPayload[i].name);

          let startTime = moment(this.barChartPayload[i].createdAt);
          let endTime = moment();
          if (endTime.diff(startTime, "seconds") >= sr) {
            this.barChartPayload[i].online = false;
          } else this.barChartPayload[i].online = true;

          if (this.barChartPayload[i].online) {
            this.barChartSeriesData.push({
              value: this.barChartPayload[i][`${dataType}`],
              itemStyle: {
                color: new echarts.graphic.LinearGradient(10, 10, 10, 1, [
                  { offset: 0, color: "#83bff6" },
                  { offset: 0.5, color: "#188df0" },
                  { offset: 1, color: "#3e77b6" },
                ]),
              },
            });
          } else {
            this.barChartSeriesData.push({
              value: this.barChartPayload[i][`${dataType}`],
              itemStyle: {
                color: "#B0BEC5",
              },
            });
          }
        }

        // Building an array of objects that store the device id and status
        let devicesToHighlight = [];
        for(let i = 0; i < this.barChartPayload.length; i++) {
          let device = {};
          device.id = self.barChartPayload[i].refDeviceId;
          device.status = self.barChartPayload[i].online;
          devicesToHighlight.push(device);
        }

        // HIGHLIGHTING THE PROPER DEVICES
        // Ask the CanvaObj to highlight the devices if they belong to the refBlueprint active
        self.$refs.canvaObj.highlightDevicesById(
          devicesToHighlight,
          refBlueprint
        );
      }
    } catch (err) {
      console.log(err);
    }
  },
};
</script>

<style scoped>
.led-box {
  height: 30px;
  width: 25%;
  margin: 10px 0;
  float: left;
}

.led-red {
  margin: 0 auto;
  width: 1vw;
  height: 1vw;
  background-color: #f00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px,
    rgba(255, 0, 0, 0.5) 0 2px 12px;
}

.led-green {
  margin: 0 auto;
  width: 1vw;
  height: 1vw;
  background-color: #abff00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px,
    #89ff00 0 2px 12px;
}

.motherSmallRes {
  height: 96vh;
  width: 100vw;
  /* border: 1px solid yellowgreen; */
}

.mother {
  height: 96vh;
  width: 80vw;
  /* border: 1px solid yellowgreen; */
}

.button {
  margin-left: 10px;
  margin-top: 10px;
}

.topObj {
  height: 45vh;
  /* border: 1px solid red; */
}

.botObj {
  height: 45vh;
  /* border: 1px solid blue; */
}

.mainButtons {
  border-radius: 20px;
  /* border: 1px solid blueviolet; */
}

.dashboard {
  border-radius: 20px;
  /* border: 1px solid brown; */
}

.blueprint {
  border-radius: 20px;
  /* border: 1px solid burlywood; */
}

.lineGraphButtons {
  border-radius: 20px;
  /* border: 1px solid burlywood; */
}

.barGraphButtons {
  border-radius: 20px;
  /* border: 1px solid burlywood; */
}

.lineGraph {
  border-radius: 20px;
  /* border: 1px solid burlywood; */
}

.barGraph {
  border-radius: 20px;
  /* border: 1px solid burlywood; */
}

.blurrEffect {
  filter: blur(2px);
}

.blurrEffectSoft {
  filter: blur(1px);
}
</style>
